.b-select-blocks-search {
  margin-top: 59px;
  &__input {
    border: none;
    border-bottom: 1px solid $color-light-text;
    font-family: Mulish;
    font-size: 14px;
    line-height: 18px;
    &:focus {
      outline: none;
    }
    ::placeholder {
      color: $color-light-text;
    }
  }
  &-empty {
    width: 100%;
    text-align: center;
  }
}
.b-select-blocks {
  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 21px -9px 0;
    overflow-y: auto;
    padding-right: 16px;
    margin-right: -16px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ebebee;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #979797;
      border-radius: 4px;
    }
  }
  &-block {
    cursor: pointer;
    margin: 9px;
    width: 160px;
    height: 160px;
    border: 1px solid #DCDEE7;
    border-radius: 4px;
    position: relative;
    padding: 18px 0;
    &__text {
      padding: 0 18px;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: $color-dark-text;
    }
    &__icon {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100px;
      > svg {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &--selected,
    &:hover {
      background-color: $hover-background-color;
      .b-select-blocks-block__text {
        color: white;
      }
      .b-select-blocks-block__icon > svg {
        fill: white;
        > path {
          fill: white;
        }
      }
    }
  }
}

@include mobile {
  .b-select-blocks-search {
    margin-top: 7px;
  }
  .b-select-blocks {
    &-container {
      margin: 11px -4.5px 0;
      padding-right: 0;
      overflow-y: unset;
    }
    &-block {
      width: calc((100vw - 48px) / 3); // 90px at w320
      height: calc((100vw - 48px) / 3); // 90px at w320
      margin: 4.5px;
      padding: calc((100vw - 48px) / 270 * 4); // 4px at w320
      &__text {
        font-size: calc((100vw - 48px) / 270 * 9); // 9px at w320
        padding: 0;
        line-height: 110%;
      }
      &__icon {
        height: calc((100vw - 48px) / 270 * 60); // 60px at w320
        svg {
          width: calc((100vw - 48px) / 270 * 60); // 60px at w320
          height: calc((100vw - 48px) / 270 * 60); // 60px at w320
        }
      }
    }
  }
}
