.b-welcome-left {
  &__quote {
    padding: 26px 0 0 33px;
    &-symbol {
      vertical-align: top;
      margin: 16px 7px 0;
    }
    &-text {
      margin-left: 7px;
      display: inline-block;
      width: 228px;
      font-size: 14px;
      line-height: 150%;
      text-transform: capitalize;
    }
  }

  &__signature {
    position: absolute;
    right: 38px;
  }

  &__silhouette {
    position: absolute;
    width: 523px;
    left: -123px;
    bottom: 0;
  }
}

.b-welcome-right {
  margin-top: 24px;
}

.b-welcome-contact-buttons {
  .b-welcome-contact-button__icon {
    display: none;
  }
}

.b-welcome-contact-value {
  cursor: pointer;
  .b-welcome-contact-value__copy {
    margin-left: 5px;
    display: none;
  }
  &:hover {
    .b-welcome-contact-value__text {
      text-decoration: underline;
    }
    .b-welcome-contact-value__copy {
      display: inline;
    }
  }
}

@include mobile {
  .b-welcome-left {
    &__quote {
      padding-top: 16px;
      padding-left: 21px;
      &-symbol {
        width: 11px;
        margin: 4px 0 0;
      }
      &-text {
        width: 169px;
        font-size: 11px;
      }
    }
    &__signature {
      width: 46px;
      top: 6px;
      right: 9px;
    }
    &__silhouette {
      width: 133px;
      left: unset;
      bottom: unset;
      top: 7px;
      right: 3px;
    }
  }
  .b-welcome-right {
    margin-top: 15px;
  }
  .b-welcome-contact-buttons {
    .btn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      margin-bottom: 8px;
    }
    .b-welcome-contact-button__text {
      display: none;
    }
    .b-welcome-contact-button__icon {
      display: block;
    }
  }

  .b-onboarding {
    .b-welcome-contact-buttons {
      .btn.btn-secondary {
        padding: unset;
      }
    }
  }
}
