.b-steps {
  position: relative;
  margin-left: 48px;
  margin-top: 104px;
  height: 370px;
  overflow: hidden;

  position: sticky;
  top: 50px;

  &__container {
    position: absolute;
    transition: top 0.25s ease-in-out 0s;
    @for $i from 1 through 7 {
      &.step-#{$i} {
        @if $i <= 2 {
          top: 0;
        } @else {
          @if $i > 5 {
            $i: 5;
          }
          top: #{-($i - 2) * 70 + 20}px;
        }
      }
    }
  }
  &__step {
    position: relative;
    min-height: 52px;
    &-marker {
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid white;
      background-color: $left-background-color;
      transition: 0.25s background-color linear;
    }
    &-line {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: white;
      left: 7.5px;
      min-height: 52px;
    }
    &-text {
      margin-left: 36px;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #c7c6f7;
      padding-bottom: 30px;
      transition: 0.25s color linear;
      &__short {
        display: none;
      }
    }
    &.b-steps__step--active {
      > .b-steps__step-marker {
        background-color: white;
      }
      > .b-steps__step-text {
        color: white;
      }
    }
  }
  &__fade {
    &--top,
    &--bottom {
      position: absolute;
      height: 100px;
      width: 100%;
    }
    &--top {
      top: 0;
      background: linear-gradient($left-background-color, rgba(143, 140, 241, 0));
    }
    &--bottom {
      bottom: 0;
      background: linear-gradient(rgba(143, 140, 241, 0), $left-background-color);
    }
  }
}

@include mobile {
  .b-steps {
    margin-top: 21px;
    margin-left: calc(100vw * 48 / 320);
    margin-right: calc(100vw * 48 / 320);
    max-width: calc(100vw * (1 - 48 / 320 - 48 / 320));
    width: 100%;
    overflow: hidden;
    height: 50px;

    position: relative;
    top: unset;

    &__container {
      display: flex;
      flex-direction: row;
      transition: left 0.25s ease-in-out 0s;
      @for $i from 1 through 7 {
        &.step-#{$i} {
          @if $i <= 2 {
            left: 0;
          } @else {
            @if $i > 5 {
              $i: 5;
            }
            left: calc(100vw * 3 / 16 * #{-($i - 2)} + 20px);
            // left: calc(100vw * 3 / 16);
          }
          top: unset;
        }
      }
    }
    &__step {
      &-line {
        min-width: calc(100vw * 3 / 16);
        height: 2px;
        min-height: unset;
        top: 32.5px;
        left: 4px;
      }
      &-marker {
        width: 12px;
        height: 12px;
        top: 28px;
        left: 4px;
      }
      &-text {
        margin-left: 0;
        margin-right: calc(100vw * 3 / 16 - 22px);
        min-width: 22px;
        &__full {
          display: none;
        }
        &__short {
          display: block;
        }
      }
    }
    &__fade--top {
      bottom: unset;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      background: linear-gradient(to left, rgba(143, 140, 241, 0), $left-background-color);
    }
    &__fade--bottom {
      bottom: unset;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background: linear-gradient(to right, rgba(143, 140, 241, 0), $left-background-color);
    }
  }
}
