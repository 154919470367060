.b-quickstart-guide-left {
  margin-top: 196px;
  margin-left: 123px;
  margin-right: 50px;
  &__title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 21px;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 23px;
    max-width: 407px;
    margin-bottom: 53px;
  }
  &__topic {
    cursor: pointer;
    max-width: 484px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 24px;
    > i {
      float: right;
    }
  }
  &__prev-topic,
  &__next-topic {
    display: none;
  }
}

.b-quickstart-guide-right {
  &__video {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &__help-label {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #878DA9;
    margin-bottom: 10px;
    text-align: center;
  }
  &__schedule-btn {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #8F8CF1;
    border: 1px solid #8F8CF1;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    padding: 11px 23px 10px;
    display:inline-block;
  }
}

@include mobile {
  .b-onboarding.b-onboarding--guide {
    .b-onboarding__left {
      height: 80px;
    }
    .b-onboarding__right {
      padding-top: 16px;
      padding-left: 15px;
      padding-right: 15px;
      .b-onboarding__title {
        margin-top: 0;
      }
      .b-onboarding__subtitle {
        margin-top: 14px;
        line-height: 16px;
      }
    }
    .b-onboarding__footer {
      margin-top: 16px;
    }
  }
  .b-quickstart-guide-left {
    margin: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    &__title,
    &__subtitle,
    &__topic:not(.active) {
      display: none;
    }
    &__topic {
      margin-bottom: 0;
      text-align: center;
      > i {
        display: none;
      }
    }
    &__prev-topic,
    &__next-topic {
      display: block;
    }
  }
  .b-quickstart-guide-right {
    .b-quickstart-guide-right__video {
      margin-top: 14px;
    }
    &__help-label,
    &__schedule-btn {
      width: 100%;
      font-size: 12px;
    }
  }
}
