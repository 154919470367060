.b-video {
  background-color: $video-background-color;
  height: 100%;
  &__play-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background:white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-50px, -50px);
  }
  &__play-button {
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-12px, -15px);
  }
  &.b-video--sm {
    .b-video__play-container {
      width: 40px;
      height: 40px;
      transform: translate(-20px, -20px);
    }
    .b-video__play-button {
      transform: translate(-5px, -12px);
    }
  }
  &.b-video--md {
    .b-video__play-container {
      width: 60px;
      height: 60px;
      transform: translate(-30px, -30px);
    }
    .b-video__play-button {
      transform: translate(-7px, -12px);
    }
  }
  &-youtube {
    position: relative;
    padding-bottom: 56.25%;
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
