.b-select-tags {
  margin-top: 50px;
  &__title {
    font-size: 18px;
    color: $color-dark-text;
    margin-bottom: 20px;
  }
  &__tag {
    cursor: pointer;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 15px;
    background-color: #EDF5FF;
    border-radius: 15px;
    padding: 2px 12px 5px;
    &-checkbox {
      display: inline-block;
      width: 14px;
      height: 14px;
      border: 1px solid #C7CBDE;
      margin-right: 5px;
      top: 6px;
      position: relative;
      img {
        position: relative;
        top: -7px;
        left: 2px;
        visibility: hidden;
      }
    }
    &-text {
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      color: #75ACF1;
    }
    &:hover,
    &--checked {
      background-color: #8F8CF1;
      .b-select-tags__tag-checkbox {
        background-color: #A7A4FC;
        border-color: #A7A4FC;
      }
      .b-select-tags__tag-text {
        color: white;
      }
    }
    &--checked .b-select-tags__tag-checkbox img {
      visibility: visible;
    }
  }
}
