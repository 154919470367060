.b-onboarding {
  min-height: 100%;
  display: flex;
  flex-direction: row;

  &__left {
    min-height: 100%;
    min-width: 400px;
    position: relative;
    background-color: $left-background-color;
    color: white;
    &-logo {
      margin: 64px 64px 0;
    }
  }

  &__right {
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 64px 88px 0px 57px;
    font-size: 16px;
    line-height: 23px;
    color: $color-light-text;
  }

  &__title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: $color-dark-text;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 23px;
    color: $color-light-text;
    margin-top: 20px;
    .b-onboarding--welcome & {
      font-size: 18px;
      margin-top: 0;
    }
  }

  &.b-onboarding--start {
    .b-onboarding__title {
      margin-top: 86px;
      text-align: center;
    }
    .b-onboarding__subtitle {
      max-width: 523px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  &.b-onboarding--guide {
    .b-onboarding__left {
      min-width: 50%;
    }
    .b-onboarding__title {
      margin-top: 37px;
      max-width: 473px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      text-transform: none;
    }
    .b-onboarding__subtitle {
      max-width: 529px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .b-onboarding__footer {
      margin: 101px -45px 0 -51px;
      padding: 20px 45px 80px 51px;
    }
    .b-onboarding__right {
      padding-left: 51px;
      padding-right: 45px;
    }
  }

  .b-onboarding__challenge {
    margin-top: 27px;
    .form-group {
      margin-top: 43px;
      label {
        font-size: 18px;
        margin-bottom: 1rem;
      }
      .form-control {
        height: 179px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;

    position: sticky;
    bottom: 0px;
    background-color: white;

    margin: 48px -88px 0 -57px;
    padding: 20px 88px 80px 57px;
    border-top: 1px solid #DCDEE7;

    &-left {
      .btn-back {
        &__text {
          display: none;
        }
      }
    }
    &-right {
      justify-content: flex-end;
      align-items: flex-end;
      display: flex;
      flex-grow: 1;
    }
  }
}

@include mobile {
  .b-onboarding {
    flex-direction: column;
    &__left {
      min-width: unset;
      height: 140px;
      &-logo {
        width: 109px;
        margin: 18px 21px 0;
      }
    }
    &.b-onboarding--steps {
      .b-onboarding__left {
        position: sticky;
        top: 0;
        z-index: 1;
        height: 80px;
        .b-onboarding__left-logo {
          display: none;
        }
      }
    }
    &__right {
      padding: 20px 15px 0px;
    }
    &__title {
      font-size: 18px;
    }

    .b-onboarding__footer {
      margin: 24px -15px 0 -15px;
      padding: 15px;
      border-top: 1px solid #DCDEE7;

      &-left {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-basis: 100%;
        > p {
          font-size: 12px;
        }

        .btn.btn-back {
          font-family: Montserrat;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 1px;
          text-transform: uppercase;
          padding: 8px 0;
          width: 100%;
          font-size: 14px;
          border: none;
          height: unset;
          .btn-back__text {
            display: inline;
          }
          .btn-back__icon {
            display: none;
          }
        }
    
      }
      &-right {
        flex-basis: 100%;
        > a {
          width: 100%;
        }
        > a > .btn.btn-lg,
        > .btn.btn-lg {
          width: 100%;
          font-size: 14px;
          padding-top: 8px;
          padding-left: 79px;
          padding-bottom: 8px;
          > i {
            top: unset;
            > img {
              width: 12px;
            }
          }
        }
      }
    }
    
    .b-onboarding__subtitle {
      font-size: 14px;
    }
    &.b-onboarding--welcome .b-onboarding__right {
      padding-top: 16px;
      .b-onboarding__title {
        font-size: 20px;
      }
      .b-onboarding__title,
      .b-onboarding__subtitle {
        text-align: center;
      }
    }
    .b-onboarding__right.b-onboarding__right--select-blocks {
      .b-onboarding__subtitle {
        font-size: 13px;
        margin-top: 7px;
        max-height: 32px;
        overflow: hidden;
        line-height: initial;
      }
    }
  }

  .b-onboarding {
    .b-onboarding__challenge {
      margin-top: 7px;
      .row {
        flex-direction: column-reverse;
        .col-6 {
          max-width: unset;
          flex: unset;
        }
        .b-onboarding__challenge-text {
          font-size: 14px;
        }
      }
      .form-group {
        margin-top: 13px;
        label {
          font-size: 14px;
          margin-bottom: 6px;
        }
        .form-control {
          height: 48px;
        }
      }

    }

    &.b-onboarding--welcome .b-onboarding__footer {
      flex-direction: column;

      border-top: none;
      position: unset;
      margin: 24px 0 0;
      padding: 0;

      .b-onboarding__footer-right {
        position: sticky;
        bottom: 0px;
        background-color: white;
        margin: 0 -15px 0 -15px;
        padding: 15px;
        border-top: 1px solid #DCDEE7;
      }
    }
  }
}
