
$color-dark-text: #373451;
$color-light-text: #878DA9;

$hover-background-color: #8F8CF1;

$left-background-color: #8F8CF1;
$video-background-color: #E9E8FC;

$btn-secondary-color: #8F8CF1;
$btn-secondary-background-color: #E9E8FC;

$link-color: #8F8CF1;

@import './inc/bem';
@import './inc/bootstrap-overrides'
