
.b-onboarding {
  .btn {
    &.btn-secondary {
      background-color: #E9E8FC;
      color: #8F8CF1;
      color: $btn-secondary-color;
      background-color: $btn-secondary-background-color;
      border-color: $btn-secondary-background-color;
      padding: 6px 29px;
    }
    &.btn-lg {
      border-radius: 0;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 14px 37px 12px 50px;
      > i {
        top: -2px;
        position: relative;
        margin-left: 30px;
      }
    }
    &.btn-back {
      width: 55px;
      height: 55px;
      border: 1px solid #C0CBDC;
      border-radius: 50%;
      > i {
        position: relative;
        top: 7px;
        left: -2px;
      }
    }
  }

  .text-success {
    color: #51d097;
  }
}
