.b-start-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 21px -9px 0;
  &__block {
    cursor: pointer;
    position: relative;
    margin: 9px;
    width: 296px;
    height: 278px;
    border: 1px solid #DCDEE7;
    border-radius: 4px;
    padding: 1px;
    text-align: center;
    &-icon {
      height: 155px;
      > img {
        top: 50%;
        position: relative;
        transform: translate(0, -50%);
      }
    }
    &-label {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: $color-dark-text;
      margin-bottom: 10px;
    }
    &-text {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
    }
    &:hover,
    &--selected {
      border-color: #8F8CF1;
      border-width: 2px;
      padding: 0;
    }
    &--selected {
      background-color: #F2F2F2;
    }
  }
}
