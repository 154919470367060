.b-learn-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 21px -9px 0;
  &__block {
    position: relative;
    margin: 9px;
    width: 160px;
    height: 160px;
    border: 1px solid #DCDEE7;
    border-radius: 4px;
    &-text {
      padding: 18px 18px 0;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: $color-dark-text;
    }
    &-icon {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &-link {
      cursor: pointer;
      position: absolute;
      right: 14px;
      bottom: 8px;
      font-size: 10px;
      line-height: 13px;
      color: $link-color;
    }
    &--selected,
    &:hover {
      background-color: $hover-background-color;
      .b-learn-blocks__block-text {
        color: white;
      }
      .b-learn-blocks__block-icon > svg {
        fill: white;
        > path {
          fill: white;
        }
      }
      .b-learn-blocks__block-link {
        color: white;
      }
    }
  }
  &__modal-title {
    margin-left: -20px;
    margin-top: -15px;
  }
}

@include mobile {
  .b-learn-blocks {
    margin: 11px -4.5px 0;
    &__block {
      width: 90px;
      height: 90px;
      margin: 4.5px;
      padding: 4px;
      &-text {
        font-size: 9px;
        padding: 0;
        line-height: 10px;
      }
      &-icon {
        width: 50px;
        height: 50px;
        > svg {
          width: 50px;
          height: 50px;
        }
      }
      &-link {
        font-size: 6px;
        right: 8px;
        bottom: 10px;
      }
    }
  }
}
